<template>
      <!-- 闸机列表 -->
     <basic-container>
       <head-layout head-title="访客记录"></head-layout>
       <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
       <grid-layout class="table-container" ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
       </grid-layout>
     </basic-container>
</template>

<script>
  import {
    visitList
  } from "@/api/safe/visitorReservation";
  import {
    getDeptTree
  } from "@/api/system/dept";
  import {
    mapGetters
  } from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import {
    getAgeFromIdCard
  } from "@/util/util";
  import {
    exportBlob
  } from "@/api/common";
  import {
    dateNow
  } from "@/util/date";
  import {
    getToken
  } from "@/util/auth";
  import {
    downloadXls
  } from "@/util/util";

  export default {
    components: {
      GridLayout,
      HeadLayout,
    },
    props: {
      prjId: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        form: {},
        query: {},
        tableLoading: true,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        selectionList: [],
        currentDeptData: [],
        treeDic: [],
        currentDeptId: '',
        tableOption: {
          // linklabel: "eqtName",
          selection: false,
          menu: false,
          column: [{
              label: "访客姓名",
              prop: "visitName",
              align: "center",
              overHidden: true,
            },
            // {
            //   label: "证件类型",
            //   prop: "idType",
            //   align: "center",
            //   // dataType: "number",
            //   props: {
            //     label: "dictValue",
            //     value: "dictKey",
            //   },
            //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=id_card_type",
            // },
            // {
            //   label: "证件号码",
            //   prop: "idNumber",
            //   align: "center",

            // },
            {
              label: "联系方式",
              prop: "contactWay",
              align: "center",
              overHidden: true,
            },
            {
              label: "访客单位",
              prop: "visitCompany",
              align: "center",
              overHidden: true,
            },
            {
              label: "被访人",
              prop: "beVisitName",
              width: 100,
              align: "center",
              overHidden: true,
            },
            {
              label: "访问事由",
              prop: "visitReason",
              width: 100,
              align: "center",
              overHidden: true,
              // dataType: "number",
              // props: {
              //   label: "dictValue",
              //   value: "dictKey",
              // },
              // dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=PrjEqGateMachineStatus",
            },
            {
              label: "进入时间",
              prop: "inTime",
              width: 100,
              align: "center",
              overHidden: true,
            },
            {
              label: "离开时间",
              prop: "outTime",
              width: 100,
              align: "center",
              overHidden: true,
            },
            {
              label: "通行状态",
              prop: "visitStatus",
              width: 100,
              align: "center",
              props: {
                label: "dictValue",
                value: "dictKey",
              },
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=prj_visitor_visit_status",
            },
            {
              label: "设备位置",
              prop: "outMachineSite",
              width: 100,
              align: "center",
              overHidden: true,
            },

            {
              label: "备注",
              prop: "remarks",
              width: 100,
              align: "center",
              overHidden: true,
            },

          ],
        },
        headBtnOptions: [

        ],
        searchColumns: [{
            label: "项目",
            prop: "prjId",
            span: 4,
            type: "tree",
            dicData: this.dicData,
            props: {
              label: 'title',
              value: 'id'
            },
            change: (column, id) => {
              this.currentDeptId = column.value
            },
            placeholder: "请选择项目",
          }, {
            label: "访客姓名",
            prop: "visitName",
            span: 4,
            placeholder: "请输入访客姓名",
          },
          {
            label: "通行状态",
            prop: "visitStatus",
            span: 4,
            placeholder: "请选择通行状态",
            type: "select",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=prj_visitor_visit_status",
          },
          {
            label: "通行时间",
            prop: "time",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            span: 4,
            type: 'daterange',
            startPlaceholder: "进入时间",
            endPlaceholder: "离开时间",
          },
        ],
        tableData: [],

      };
    },
    computed: {
      ...mapGetters(["permission","userInfo"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.thirduser_add, false),
          viewBtn: this.vaildData(this.permission.thirduser_view, false),
          delBtn: this.vaildData(this.permission.thirduser_delete, false),
          editBtn: this.vaildData(this.permission.thirduser_edit, false),
        };
      },
    },

    mounted() {
      this.$nextTick(() => {
        this.$refs.gridHeadLayout.searchForm = {
          visitStatus: 'already_entered'
        }
        this.getDeptTreeList();

      });

    },
    methods: {
      getDeptTreeList() {
        let parentId = this.userInfo.dept_id
        getDeptTree('', '5,2', parentId).then(res => {
          this.treeDic = this.setDisabled(res.data.data)
          this.searchColumns[0].dicData = this.setDisabled(res.data.data)
          if(this.treeDic[0].deptCategory=='5'){
            this.currentDeptId = this.treeDic[0].id
            this.currentDeptData = this.treeDic[0]
            this.$nextTick(() => {
              this.$refs.gridHeadLayout.searchForm = {
                prjId: this.treeDic[0].id
              }
            this.onLoad(this.page, {
              prjId: this.currentDeptId
            });
          })
          }else{
            if(this.treeDic.length&&this.treeDic[0].children.length){
              this.currentDeptId = this.treeDic[0].children[0].id
              this.currentDeptData = this.treeDic[0].children[0]
              this.$nextTick(() => {
                this.$refs.gridHeadLayout.searchForm = {
                  prjId: this.treeDic[0].children[0].id
                }
                this.onLoad(this.page, {
                  prjId: this.currentDeptId
                });
              })
            }
          }
        })
      },
      setDisabled(tree) {
        tree.forEach(item => {
          if (item.children) {
            this.setDisabled(item.children)
          }
          item.disabled = item.deptCategory == 5 || item.deptCategory == 3 ? false : true;
        })
        return tree
      },

      getAge(data) {
        return getAgeFromIdCard(data);
      },


      // 搜索
      gridHeadSearch(searchForm) {
        if (searchForm.time) {
          searchForm.inTime = searchForm.time[0] + ' 00:00:00';
          searchForm.outTime = searchForm.time[1] + ' 23:59:59';
        } else {
          searchForm.inTime = ''
          searchForm.outTime = ''
        }
        // if (searchForm.prjId) {
        //   this.currentDeptId = searchForm.prjId
        // }
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.currentDeptId = this.treeDic[0].id
        // this.$refs.gridHeadLayout.searchForm = {
        //     prjId: this.treeDic[0].id
        //   }
        this.onLoad(this.page, searchForm);
      },
      onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        visitList(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.$refs.gridHeadLayout.searchForm)
        ).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
          }
        });
      },
      // 导入
      handleImport() {
        this.excelBox = true;
        this.excelOption.excelForm.excelFile = [];
      },
      uploadAfter(res, done, loading, column) {
        window.console.log(column);
        this.excelBox = false;
        this.onLoad(this.page);
        this.excelOption.excelForm.excelFile = [];
        done();
      },
      handleTemplate() {
        exportBlob(
          `/api/sinoma-hse-prj/thirduser/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, "相关方人员模板" + ".xlsx");
        });
      },
      // 导出
      handleExport() {
        this.$confirm("是否导出相关方人员数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          exportBlob(
            `/api/sinoma-hse-prj/thirduser/export?userName=${
            this.$refs.gridHeadLayout.searchForm.userName
              ? this.$refs.gridHeadLayout.searchForm.userName
              : ""
          }&isUsed=${
            this.$refs.gridHeadLayout.searchForm.isUsed
              ? this.$refs.gridHeadLayout.searchForm.isUsed
              : ""
          }&updateTime=${
            this.$refs.gridHeadLayout.searchForm.updateTime
              ? this.$refs.gridHeadLayout.searchForm.updateTime
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
          ).then((res) => {
            downloadXls(res.data, `相关方人员${dateNow()}.xlsx`);
          });
        });
      },
    },
  };

</script>

