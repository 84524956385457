<template>
    <div class="form-dashed-head">
      <i class="form-dashed-head__icon" :class="icon"></i>
      <h1 class="form-dashed-head__title">{{title}}</h1>
    </div>
  </template>
  <script>
  export default {
    props: {
      title: String,
      icon: {
        type:String,
        default:'el-icon-s-order'
      }
    }
  }
  </script>
  <style lang="scss" scoped>
    .form-dashed-head{
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: auto;
      .form-dashed-head__icon{
        margin-right: 8px;
        font-size: 20px;
        color: #2984f8;
      }
      .form-dashed-head__title{
        font-weight: 600;
        font-size: 13px;
        color: #333333;
      }
      &::after{
        content: '';
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed #e2e4e8;
        flex: 1;
      }
    }
  </style>