var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    { staticClass: "content" },
    [
      _vm.xiafaState !== "2"
        ? _c(
            "avue-skeleton",
            {
              staticStyle: { padding: "0" },
              attrs: { loading: _vm.waiting, avatar: "", rows: 8 },
            },
            [
              _c(
                "avue-affix",
                { attrs: { id: "avue-view", "offset-top": 92 } },
                [
                  _c(
                    "div",
                    { staticClass: "header" },
                    [
                      _c("avue-title", {
                        attrs: { value: _vm.paModelInfoName },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { position: "relative", overflow: "hidden" } },
        [
          _vm.xiafaState !== "2"
            ? _c(
                "avue-affix",
                { attrs: { id: "avue-view", "offset-top": 152 } },
                [
                  _c("anchor", {
                    attrs: {
                      anchorList: _vm.anchorMap || [],
                      container: ".avue_scrool",
                      offsetTop: -15,
                      initTop: 40,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.baseGroup, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "articles", attrs: { id: item.id } },
              [
                item.isTable === "N" && item.isUeditor === "N"
                  ? _c(
                      "collapsenew",
                      {
                        attrs: { title: item.label, id: "part" + (index + 2) },
                      },
                      [
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _c("form-group", {
                              ref: "gudingzichanForm",
                              refInFor: true,
                              attrs: {
                                column: item.column,
                                isDetail: _vm.isDetail,
                                detailData: _vm.detailData,
                              },
                              model: {
                                value: _vm.gudingzichanForm,
                                callback: function ($$v) {
                                  _vm.gudingzichanForm = $$v
                                },
                                expression: "gudingzichanForm",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                item.isTable === "Y"
                  ? _c(
                      "collapsenew",
                      { attrs: { title: item.label } },
                      [
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 22 } },
                              _vm._l(item.allData, function (ele, i) {
                                return _c(
                                  "el-col",
                                  { key: i, attrs: { span: item.columnNum } },
                                  [
                                    _c("TableDetails", {
                                      key: i,
                                      attrs: {
                                        allTableData: ele,
                                        isDetail: _vm.isDetail,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                item.isUeditor === "Y"
                  ? _c(
                      "collapsenew",
                      { attrs: { title: item.label } },
                      [
                        _c("template", { slot: "content" }, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(item.ueditor.ueditorText),
                            },
                          }),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "btns",
          style: _vm.isCollapse
            ? "width:calc(100% - 100px)"
            : "width: calc(100% - 252px)",
        },
        [
          _vm.paModelStatus === "0"
            ? _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.submitLoading,
                    size: "medium",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setOpenStatus("1")
                    },
                  },
                },
                [_vm._v("启用")]
              )
            : _vm._e(),
          _vm.paModelStatus === "1"
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.setOpenStatus("0")
                    },
                  },
                },
                [_vm._v("停用")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }