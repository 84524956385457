var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          ref: "singleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-key": "id",
            "default-expand-all": "true",
            "cell-style": "fontSize:12px",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
            "header-row-style": "backgroundColor: #f5f7fa",
            "header-cell-style": "backgroundColor: #f5f7fa",
            "header-row-class-name": "headerClass1",
          },
          on: { "current-change": _vm.handleCurrentChange },
        },
        _vm._l(_vm.headerData, function (item, index) {
          return _c(
            "el-table-column",
            {
              key: index,
              attrs: {
                prop: item.children && item.children.length > 0 ? "" : item.key,
                label: item.name,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row[item.key].textStyle == "2" && !_vm.isDetail
                          ? _c("el-input", {
                              attrs: { size: "mini", placeholder: "请输入" },
                              model: {
                                value: scope.row[item.key].name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row[item.key], "name", $$v)
                                },
                                expression: "scope.row[item.key].name",
                              },
                            })
                          : _c("div", { staticClass: "showLabel" }, [
                              scope.row[item.key].isbt == "Y"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#ff3f3b" } },
                                    [_vm._v("*")]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row[item.key].name) +
                                  "\n          "
                              ),
                              scope.row[item.key].isTips == "Y"
                                ? _c("img", {
                                    staticStyle: {
                                      cursor: "pointer",
                                      width: "14px",
                                      height: "14px",
                                      "vertical-align": "middle",
                                      "margin-top": "-3px",
                                      display: "inline-block",
                                    },
                                    attrs: {
                                      src: require("@/assets/images/doubt.svg"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tableShowTips(
                                          scope.row[item.key]
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            _vm._l(item.children, function (ele, i) {
              return item.children.length > 0
                ? _c("el-table-column", {
                    key: i,
                    attrs: { prop: ele.key, label: ele.name },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row[ele.key].textStyle == "2" &&
                              _vm.isDetail
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row[ele.key].name) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              scope.row[ele.key].textStyle == "2" &&
                              !_vm.isDetail
                                ? _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: scope.row[ele.key].name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row[ele.key],
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row[ele.key].name",
                                    },
                                  })
                                : scope.row[ele.key].textStyle == "2" &&
                                  _vm.isDetail
                                ? _c("div")
                                : _c("div", { staticClass: "showLabel" }, [
                                    scope.row[ele.key].isbt == "Y"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#ff3f3b" } },
                                          [_vm._v("*")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n\n            " +
                                        _vm._s(scope.row[ele.key].name) +
                                        "\n            "
                                    ),
                                    scope.row[ele.key].isTips == "Y"
                                      ? _c("img", {
                                          staticStyle: {
                                            cursor: "pointer",
                                            width: "14px",
                                            height: "14px",
                                            "vertical-align": "middle",
                                            "margin-top": "-3px",
                                            display: "inline-block",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/doubt.svg"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.tableShowTips(
                                                scope.row[ele.key]
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e()
            }),
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "base-content-form" },
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: _vm.tipsObj.name,
                modal: false,
                visible: _vm.drawer,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c("ul", { staticClass: "tips-wrapper" }, [
                _c("li", [
                  _c("span", { staticClass: "label" }, [_vm._v("属性名称:")]),
                  _c("p", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.tipsObj.name)),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "label" }, [_vm._v("属性说明:")]),
                  _c("p", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.tipsObj.tips)),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "label" }, [_vm._v("填写示例:")]),
                  _c("p", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.tipsObj.sl)),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }