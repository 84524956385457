<template>
  <el-dialog :title="'新增人员1'" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog"
    width="80%" class="qmDialog addCorePersonnelDialog" @close="closeDialog" @open="setOrganizationPersonnelInit">
    <selectOPRowDialog v-if="showDialog" :roleId="roleId" ref="selectOPRowDialog" :isIndex="false" :dataForm="dataForm"
      @selectOPList="selectOPList"></selectOPRowDialog>
    <span slot="footer">
      <el-button size="small" @click="closeDialog">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button size="small" type="primary" @click="handleSave" :loading="dialogLoading">
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import selectOPRowDialog from "@/views/business/safe/itemList/organizationPersonnel.vue";
export default {
  components: {
    selectOPRowDialog,
  },
  props: ['dataForm'],
  data() {
    return {
      showDialog: false,
      opData: [],
      dialogLoading: false,
      selectData: [],
      roleId: ''
    };
  },
  methods: {
    init(data, roleLeftData) {
      console.log('data', data)
      this.roleId = roleLeftData.id
      this.showDialog = true;
      this.opData = data
    },
    setOrganizationPersonnelInit() {
      this.$nextTick(() => {
        this.$refs.selectOPRowDialog.init()
      })
    },
    // 选择组织&人员
    selectOPList(data) {
      this.selectData = data
    },
    handleSave() {
      this.$emit('changeOPData', this.selectData)
      this.closeDialog()
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .addCorePersonnelDialog .el-tree {
  // height: calc(100vh - 435px) !important;
  height: 50vh !important;
  max-height: 50vh !important;
}

::v-deep .addCorePersonnelDialog .avue-crud .el-table {
  //height: calc(100vh - 435px) !important;
  //max-height: calc(100vh - 435px) !important;
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
