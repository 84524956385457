<template>
    <div class="form-group-collapse-new" :class="'form-group-collapse-spe'">
      <el-collapse value="1">
        <el-collapse-item name="1">
          <template slot="title">
  
            <span class="group2-title">
              <i class="expend-wrap"></i>{{title}}
            </span>
            <div class="btn-wrapper">
               <slot name="btn" />
            </div>
          </template>
          <div>
            <slot name="content"/>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </template>
  <script>
  export default {
    props: {
      title: String,
      cType: {
        type:String,
        default:'1'
      }
    },
    data () {
      return {
        activeNames: ''
      }
    }
  
  }
  </script>
  <style lang="scss" scoped>
  .form-group-collapse-new ::v-deep {
    .el-collapse,.el-collapse-item__wrap {
      border: none;
    }
    .el-collapse-item__wrap{
      background: none;
    }
    .el-collapse-item__content{
      padding-bottom: 0;
    }
    .el-collapse-item:last-child{
      margin-bottom: 0;
    }
    .btn-wrapper{
      position: absolute;
      right: 20px;
    }
    .el-collapse-item__header{
      position: relative;
      .el-collapse-item__arrow{
        position: absolute;
        left: 2px;
        font-weight: bold;
        color: #2984f8;
        font-size: 12px;
        top: 11px;
      }
    }
    .el-collapse-item.is-disabled{
      .el-collapse-item__header::after{
        content:'';
      }
    }
    .el-input__inner{
      color: #111;
    }
    .el-input.is-disabled{
      .el-input__inner{
        color: #333;
      }
    }
  }
  .form-group-collapse-spe ::v-deep {
    
    .el-collapse-item__header {
      border-top: none;
      line-height: normal;
      height: 32px;
      margin-bottom: 10px;
      &.is-active {
        border-bottom-color: #e2e4e8;
      } 
      .header-icon{
        color: #2984f8;
        margin-right: 5px;
      }
      .group2-title{
        font-weight: bold;
        padding-left: 22px;
        font-size: 16px;
        .expend-wrap{
          width: 15px;
          height: 15px;
          border:1px solid #2984f8;
          display: block;
          position: absolute;
          left: 1px;
          border-radius: 2px;
          box-sizing: border-box;
          top: 9px;
        }
      }
    }
  }
  .form-group-collapse-two ::v-deep {
    .el-collapse-item__header {
      border-top: 1px dashed #e2e4e8;
      position: relative;
      border-bottom: none;
      line-height: normal;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      .group2-title {
        background: #f9fbff;
        border: 1px solid #e5e5e5;
        border-bottom: none;
        padding: 5px 10px;
        font-size: 12px;
        text-align: center;
        color: #2984f8;
        position: absolute;
        bottom: 0;
        left: 15px;
      }
    }
  }
  </style>