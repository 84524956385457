var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group-collapse-new",
      class: "form-group-collapse-spe",
    },
    [
      _c(
        "el-collapse",
        { attrs: { value: "1" } },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticClass: "group2-title" }, [
                  _c("i", { staticClass: "expend-wrap" }),
                  _vm._v(_vm._s(_vm.title) + "\n        "),
                ]),
                _c("div", { staticClass: "btn-wrapper" }, [_vm._t("btn")], 2),
              ]),
              _c("div", [_vm._t("content")], 2),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }