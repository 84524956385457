var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-dashed-head" }, [
    _c("i", { staticClass: "form-dashed-head__icon", class: _vm.icon }),
    _c("h1", { staticClass: "form-dashed-head__title" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }