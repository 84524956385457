var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group", staticStyle: { padding: "15px 0" } },
    [
      _c("avue-form", {
        ref: "form",
        attrs: { option: _vm.option },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.labelSlotList, function (item) {
              return {
                key: `${item.prop}Label`,
                fn: function (scope) {
                  return [
                    scope.column.labelslot
                      ? _c("span", { key: item.prop }, [
                          _c("span", [
                            _vm._v(_vm._s(scope.column.label) + ":"),
                          ]),
                          _c("img", {
                            staticStyle: {
                              cursor: "pointer",
                              width: "14px",
                              height: "14px",
                              "vertical-align": "middle",
                              "margin-top": "-3px",
                              display: "inline-block",
                            },
                            attrs: {
                              src: require("@/assets/images/doubt.svg"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showTips(scope.column)
                              },
                            },
                          }),
                        ])
                      : _c("span", { key: item.prop }, [
                          _c("span", [
                            _vm._v(_vm._s(scope.column.label) + ":"),
                          ]),
                        ]),
                    scope.column.labelExtra
                      ? _c(
                          "div",
                          {
                            key: item.prop + "1",
                            staticStyle: {
                              "line-height": "14px",
                              "margin-right": "15px",
                              color: "#f56c6c",
                              "margin-top": "-2px",
                            },
                          },
                          [_vm._v("\n        来源数据中台\n      ")]
                        )
                      : _vm._e(),
                  ]
                },
              }
            }),
          ],
          null,
          true
        ),
        model: {
          value: _vm.dataForm,
          callback: function ($$v) {
            _vm.dataForm = $$v
          },
          expression: "dataForm",
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: { title: _vm.tipsTitle, modal: false, visible: _vm.drawer },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("ul", { staticClass: "tips-wrapper" }, [
            _c("li", [
              _c("span", { staticClass: "label" }, [_vm._v("属性名称:")]),
              _c("p", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.tipsObj.label)),
              ]),
            ]),
            _c("li", [
              _c("span", { staticClass: "label" }, [_vm._v("属性说明:")]),
              _c("p", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.tipsObj.columnComment)),
              ]),
            ]),
            _c("li", [
              _c("span", { staticClass: "label" }, [_vm._v("填写示例:")]),
              _c("p", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.tipsObj.commentExample)),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }