<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="title"
      :before-close="handleCancel"
    >
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="form.userName"
                @focus="selectPeople"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组织名称" prop="orgName">
              <el-input disabled v-model="form.orgName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="行程" prop="tripState">
              <el-select v-model="form.tripState" placeholder="请选择行程状态">
                <el-option
                  v-for="item in trip_state"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出发时间" prop="startDate">
              <el-date-picker
                v-model="form.startDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择出发时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="出发地" prop="startPlace">
              <el-input
                v-model="form.startPlace"
                @focus="handleRegionOPen('startPlace')"
                placeholder="请选择出发地"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的地" prop="endPlace">
              <el-input
                v-model="form.endPlace"
                @focus="handleRegionOPen('endPlace')"
                placeholder="请选择目的地"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                v-model="form.remark"
                placeholder="请填写备注"
                :autosize="{ minRows: 2 }"
                  show-word-limit
                  maxlength="200"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button size="small" @click="handleCancel" :loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSubmit"
          :loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>

    <el-dialog
      :title="`请选择${regionTitle}`"
      v-dialogDrag
      :visible.sync="regionModel"
      width="40%"
      class="region-dialog"
    >
      <avue-tree
        :option="treeOption"
        ref="avueTree"
        v-loading="treeLoading"
        :data="treeData"
        @node-click="nodeClick"
      />
      <template slot="footer">
        <el-button size="small" @click="cancel()" :loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          :loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
    <selectPeopleByCurrentPrjoect
      ref="selectPeopleByCurrentPrjoect"
      @callback="handleCallback"
    ></selectPeopleByCurrentPrjoect>
  </div>
</template>

<script>
import { dictionaryBiz } from "@/api/reportTasks";
import * as API from "@/api/siteManagement/personEAExit";
import filterTreeDataByAttrMixins from "@/views/siteManagement/personEAExit/mixins";
import { getLazyTree, getAllTree } from "@/api/base/region";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import selectPeopleByCurrentPrjoect from "./selectPeopleByCurrentPrjoect.vue";
import { mapGetters } from "vuex";
export default {
  mixins: [filterTreeDataByAttrMixins],
  components: {
    UserDetpDialog,
    selectPeopleByCurrentPrjoect,
  },
  data() {
    return {
      dialogVisible: false,
      title: "",
      form: {
        tripState: "",
        orgId: "",
        orgName: "",
        prjId: "",
        prjName: "",
        remark: "",
        sex: "",
        startDate: "",
        startPlace: "",
        userName: "",
        userId: "",
        endPlace: "",
      },
      rules: {
        userName: [
          { required: true, message: "请选择姓名", trigger: "change" },
        ],
        orgName: [
          { required: true, message: "请选择组织名称", trigger: "change" },
        ],
        tripState: [
          { required: true, message: "请选择行程", trigger: "change" },
        ],
        startDate: [
          { required: true, message: "请选择出发时间", trigger: "change" },
        ],
        startPlace: [
          { required: true, message: "请选择出发地", trigger: "change" },
        ],
        endPlace: [
          { required: true, message: "请选择目的地", trigger: "change" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      dialogLoading: false,
      trip_state: [],
      treeLoading: false,
      treeData: [],
      treeOption: {
        nodeKey: "id",
        lazy: false,
        multiple: false,
        addBtn: false,
        menu: false,
        size: "small",
        defaultExpandAll: true,
        props: {
          // labelText: "标题",
          // label: "title",
          // value: "value",
          // children: "children",
          children: "childList",
          label: "name",
          value: "code",
        },
      },
      topCode: "10",
      regionModel: false,
      regionTitle: "",
      regoonType: "",
      selecRegionData: {},
      prjName: "",
      prjId: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    init(type, prjObj, row) {
      this.title = type == "add" ? "新增人员出入境" : "编辑人员出入境";

      for (let key in this.form) {
        if (key in row) {
          this.form[key] = key == "tripState" ? String(row[key]) : row[key];
        } else {
          this.form[key] = "";
        }
      }
      if (type == "edit") this.form.id = row.id;
      if (type == "add") {
        this.form.prjId = prjObj.prjId;
        this.form.prjName = prjObj.prjName;
        this.form.orgId = this.userInfo.dept_id;
        this.form.orgName = this.userInfo.deptName;
        this.form.userId = this.userInfo.user_id;
        this.form.userName = this.userInfo.real_name;
        this.form.sex = this.userInfo.sex;
      }
      this.dialogVisible = true;
      dictionaryBiz("trip_state").then((res) => {
        this.trip_state = res.data.data;
      });
    },

    handleCancel() {
      this.$refs.form.resetFields();
      this.dialogLoading = false;
      this.dialogVisible = false;
    },

    selectPeople() {
      this.$refs.selectPeopleByCurrentPrjoect.init(
        this.form.prjId,
        this.form.prjName
      );
    },

    handleCallback(row) {
      console.log("选择人员回调=>", row);
      this.form.orgId = row.deptId;
      this.form.orgName = row.deptName;
      this.form.userId = row.userId;
      this.form.userName = row.userName;
      this.form.sex = row.sex;
    },

    initTree() {
      this.treeData = [];
      // getLazyTree(this.topCode).then((res) => {
      //   this.treeData = res.data.data.map((item) => {
      //     return {
      //       ...item,
      //       leaf: !item.hasChildren,
      //     };
      //   });
      // });
      this.treeLoading = true

      getAllTree().then((res) => {
        this.treeLoading = false
        this.treeData = this.filterTreeDataByAttr(res.data.data).map((item) => {
          return {
            ...item,
            leaf: !item.hasChild,
          };
        });
      });
    },

    handleRegionOPen(type) {
      this.initTree();
      this.regionTitle = type === "startPlace" ? "出发地" : "目的地";
      this.regoonType = type;
      this.regionModel = true;
    },
    nodeClick(data) {
      if(data.regionLevel){
        this.selecRegionData = data;
      }else{
        this.$message.warning('只能选择国家')
      }
    },
    cancel() {
      this.$refs.avueTree.setCurrentKey();
      this.$refs.avueTree.filterValue='';
      this.dialogLoading = false;
      this.regionModel = false;

    },
    handleSave() {
      if(!this.selecRegionData.regionLevel){
        this.$message.warning('只能选择国家')
        return false
      }
      this.regionModel = false;
      if (this.regoonType === "startPlace") {
        this.form.startPlace = this.selecRegionData.name;
      }
      if (this.regoonType === "endPlace") {
        this.form.endPlace = this.selecRegionData.name;
      }
      this.$refs.avueTree.setCurrentKey();
      this.$refs.avueTree.filterValue='';
      this.selecRegionData = {};
    },

    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // if(this.type=='edit') this.form.id = row.id;
          if(this.form.startPlace===this.form.endPlace){
            this.$message.warning('出发地和目的地不能为一个地方,请重新选择')
            return false
          }
          this.dialogLoading = true;
          API.submit(this.form)
            .then((res) => {
              this.$message.success("操作成功");
              this.handleCancel();
              this.$emit("callback");
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.region-dialog {
  ::v-deep .el-dialog {
    position: relative;
  }
  ::v-deep .el-dialog__body {
    overflow-y: auto;
    height: 400px;
  }
  ::v-deep .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20001;
  }
}</style>
