var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "link-header" }, [
      _c(
        "ul",
        _vm._l(_vm.anchorList, function (item, i) {
          return _c(
            "li",
            {
              key: i,
              class: { activeTop: _vm.active == i },
              on: {
                click: function ($event) {
                  return _vm.toToc(item, i)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(item.name))])]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }