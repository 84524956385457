<template>
    <div>
      <el-table
        ref="singleTable"
        :data="tableData"
        border
        @current-change="handleCurrentChange"
        row-key="id"
        default-expand-all="true"
        cell-style="fontSize:12px"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        header-row-style="backgroundColor: #f5f7fa"
        header-cell-style="backgroundColor: #f5f7fa"
        header-row-class-name="headerClass1"
        style="width: 100%"
      >
        <el-table-column
          :key="index"
          :prop="item.children && item.children.length > 0 ? '' : item.key"
          :label="item.name"
          v-for="(item, index) in headerData"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row[item.key].textStyle == '2' && !isDetail"
              v-model="scope.row[item.key].name"
              size="mini"
              placeholder="请输入"
            />
            <!-- <div
              v-else-if="scope.row[item.key].textStyle == '2' && isDetail"
            ></div> -->
            <div v-else class="showLabel">
              <span v-if="scope.row[item.key].isbt == 'Y'" style="color: #ff3f3b"
                >*</span
              >
              {{ scope.row[item.key].name }}
              <img
                :src="require('@/assets/images/doubt.svg')"
                @click="tableShowTips(scope.row[item.key])"
                style="
                  cursor: pointer;
                  width: 14px;
                  height: 14px;
                  vertical-align: middle;
                  margin-top: -3px;
                  display: inline-block;
                "
                v-if="scope.row[item.key].isTips == 'Y'"
              />
            </div>
          </template>
          <el-table-column
            :prop="ele.key"
            v-if="item.children.length > 0"
            :key="i"
            :label="ele.name"
            v-for="(ele, i) in item.children"
          >
            <template slot-scope="scope">
              <span v-if="scope.row[ele.key].textStyle == '2' && isDetail">{{scope.row[ele.key].name}} </span>
              <el-input
                v-if="scope.row[ele.key].textStyle == '2' && !isDetail"
                v-model="scope.row[ele.key].name"
                size="mini"
                placeholder="请输入"
              />
              <div
                v-else-if="scope.row[ele.key].textStyle == '2' && isDetail"
              ></div>
              <div v-else class="showLabel">
                <span v-if="scope.row[ele.key].isbt == 'Y'" style="color: #ff3f3b"
                  >*</span
                >
  
                {{ scope.row[ele.key].name }}
                <img
                  :src="require('@/assets/images/doubt.svg')"
                  @click="tableShowTips(scope.row[ele.key])"
                  style="
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                    vertical-align: middle;
                    margin-top: -3px;
                    display: inline-block;
                  "
                  v-if="scope.row[ele.key].isTips == 'Y'"
                />
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="base-content-form">
        <el-drawer :title="tipsObj.name" :modal="false" :visible.sync="drawer">
          <ul class="tips-wrapper">
            <li>
              <span class="label">属性名称:</span>
              <p class="content">{{ tipsObj.name }}</p>
            </li>
            <li>
              <span class="label">属性说明:</span>
              <p class="content">{{ tipsObj.tips }}</p>
            </li>
            <li>
              <span class="label">填写示例:</span>
              <p class="content">{{ tipsObj.sl }}</p>
            </li>
          </ul>
        </el-drawer>
      </div>
    </div>
  </template>
       
   <script>
  import FormGroup from "./external/components/form-group2";
  
  export default {
    components: {
      FormGroup,
    },
    props: {
      isDetail: Boolean,
      allTableData: {},
      detailData: Object,
    },
    watch: {
      detailData: {
        handler(newValue, oldValue) {
         if(newValue) {
          this.extractKeys(this.tableData,newValue)
         }
        },
        deep: true,
        immediate: true,
      },
    },
  
    data() {
      return {
        tableData: [],
        headerData: [],
        drawer: false,
        tipsObj: {},
      };
    },
    created() {
      this.init();
    },
    methods: {
      extractKeys(array,arr2) {
        const result = {};
  
        for (const item of array) {
          for (const [key, value] of Object.entries(item)) {
            if (typeof value.key === "string" && value.key !== "") {
              // result[value.key] = value.name;
              console.log(value,'???',arr2);
              value.name = arr2[value.key]
              // if(arr2[value.key] === arr2) {
              // value.name = arr2[value.key]
            }
            if (value.length > 0) {
              const childResult = this.extractKeys(value,arr2);
              Object.assign(result, childResult);
            }
          }
        }
  
        return result;
      },
      init() {
        this.tableData = this.allTableData.td;
        this.headerData = this.allTableData.th;
      },
  
      tableShowTips(row) {
        this.drawer = true;
        console.log("row", row);
        this.tipsObj = row;
      },
    },
  };
  </script>
       
       <style lang="scss" scoped>
  :deep(.el-table [class*="el-table__row--level"] .el-table__expand-icon) {
    visibility: hidden;
  }
  .headerClass1 th {
    background: #f5f7fa !important;
  }
  .headerClass1 {
    th.el-table__cell {
      background-color: #f5f7fa !important;
    }
  }
  .tableColumn {
    display: flex;
    justify-content: space-between;
    .popover {
      width: 20px !important;
    }
  }
  .showLabel {
    display: inline-flex;
    align-items: center;
  }
  .tips-wrapper {
    padding: 0 15px;
    li {
      list-style: none;
      border-bottom: 1px solid #e2e3e7;
      padding: 15px 0;
      display: flex;
      .label {
        width: 85px;
        color: #9c9c9c;
      }
      .content {
        flex: 1;
        color: #000000;
        margin: 0;
      }
    }
  }
  </style>
       