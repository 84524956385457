var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "0 15px" } },
    [
      _c("form-head", {
        attrs: { title: "表格表格表格表格表格表格表格表格 " },
      }),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, size: "mini", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "date", label: "日期", width: "180" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "姓名", width: "180" },
          }),
          _c("el-table-column", { attrs: { prop: "address", label: "地址" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }